<template>
    <div class="content" style="padding: 0 200px;">
        <div class="menu-p">
            <div class="menu-p-item" v-for="item, index in  menuP " :key="index" @click="checkMenu(index, item)"
                :class="{ 'menu-p-item-active': currentP == index, 'menu-p-item-check': index == currentP }">
                <div style="width: 30px;" :class="{ 'other': index == 0 }">
                    <el-image v-if="currentP != index" :src="src[index]"  />
                    <el-image v-if="currentP == index" :src="srcChexk[index]" />
                </div>
                <div class="menu-p-text">{{ item.name }}</div>
            </div>
        </div>
        <div class="video-list">
            <div class="menu-c">
                <div class="menu-c-item" :class="{ 'menu-c-item-check': index == currentC }" v-for="item, index in menuC"
                    :key="index" @click="select(index, item)">
                    {{ item.name }}
                </div>
            </div>
            <div class="menu-gc" v-if="menuGC.length > 0">
                <span class="menu-gc-item" :class="{ 'menu-gc-item-active': index == currentGC }" v-for="item,index in menuGC" :key="index" @click="selectGC(index, item)">
                    {{ item.name }}
                </span>
            </div>
            <div class="list">
                <div class="list-item" v-for="item, index in list.rows" :key="index"  @click="play(item)">
                    <div class="item-image">
                        <img :src="item.cover" class="image" height="230"
                            style="width: 100%; border-radius: 20px;" />
                    </div>
                    <div class="item-title">{{ item.title }}</div>
                    <div class="item-time">{{ timeFormat(item.published_time) }}</div>
                </div>
            </div>
            <div style="display: flex; justify-content: center; padding: 10px 0">
                <el-pagination background :page-size="list.pageSize" :current-page="list.page" :total="list.total"
                @current-change="pageChange" layout="prev, pager, next, total" />
    </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import axios from '@/libs/axios'
import videoIcon_1 from '@/assets/videoIcon_1.png'
import videoIcon_1_check from '@/assets/videoIcon_1_check.png'
import videoIcon_2 from '@/assets/videoIcon_2.png'
import videoIcon_2_check from '@/assets/videoIcon_2_check.png'
import { useRouter } from 'vue-router'

const router = useRouter()

const src = ref([videoIcon_1, videoIcon_2])
const srcChexk = [videoIcon_1_check, videoIcon_2_check]

const play = (video) => {
  router.push({
    name: 'Play',
    query: {
      id: video.id
    }
  })
}

// // 父级menu
const currentP = ref(0)
const menuP = ref([])
const checkMenu = async (index, item) => {
    currentP.value = index
    parent_id.value = item.id
    //   await getAtypes()
    currentGC.value = null
    await getAtypes()
}
const getAtypesParent = async () => {
    const {
        data
    } = await axios.get('/atypes/parentType', {
        params: {
            page: 1,
            pageSize: 99,
            type: 2
        }
    })
    menuP.value = data
    // 加载子分类
    parent_id.value = menuP.value[0].id
    await getAtypes()
    // 初始化每个类型的分页信息
    // getArticles()
}

// 子菜单
const currentC = ref(0)
const type_id = ref(null)
const select = async (index, item) => {
    currentC.value = index
    type_id.value = item.id
    list.page = 1
    parent_id.value = item.id
    currentGC.value = null
    await getAtypesGC()
    await getVideos()
}

// 第三级菜单
const currentGC = ref(null)
// const type_id = ref(null)
const selectGC = async (index, item) => {
    currentGC.value = index
    type_id.value = item.id
    list.page = 1
    await getVideos()
}

const menuC = ref([])
const menuGC = ref([])
const parent_id = ref(null)
const getAtypes = async (type = 1) => {
    const { data, total } = await axios.get('/atypes/chirldType', {
        params: { page: 1, pageSize: 99, parent_id: parent_id.value }
    })
    menuC.value = data
    console.log('menuC.value :>> ', menuGC.value)
    type_id.value = menuC.value[0].id
    parent_id.value = menuC.value[0].id
    await getAtypesGC()
    await getVideos()
}

const getAtypesGC = async (type = 1) => {
    const { data, total } = await axios.get('/atypes/chirldType', {
        params: { page: 1, pageSize: 99, parent_id: parent_id.value }
    })
    menuGC.value = data
}

const list = reactive({
    page: 1,
    pageSize: 12,
    rows: [],
    total: 0
})

const pageChange = (page) => {
  list.page = page
  getVideos()
}

const getVideos = async () => {
    const { data, total } = await axios.get('/videos/gets', {
        params: { page: list.page, pageSize: list.pageSize, type_id: type_id.value }
    })
    list.rows = data
    //   console.log('list.data :>> ', list.rows)
    list.total = total
}
onMounted(async () => {
    //   await getBanners()
    // await getAtypes()
    await getAtypesParent()
})

const timeFormat = (isoDate) => {
    const date = new Date(isoDate)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}年${month}月${day}日`
}
</script>

<style lang="less" scoped>
.content {
    display: flex;
    justify-content: flex-start;

    .menu-p {
        width: 19%;
        height: 200px;
        padding: 0 3%;

        .menu-p-item {
            border-radius: 25px;
            font-size: 18px;
            font-weight: bold;
            height: 50px;
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            justify-items: centers;
            padding-left: 20px;
            color: rgb(128, 128, 128);

            .menu-p-text {
                margin-left: 15px;
            }

            .other {
                margin-top: 10px;
            }
        }

        .menu-p-item:hover {
            background-color: rgb(247, 247, 247);
        }

        .menu-p-item-check {
            background-color: rgb(247, 247, 247);
        }
    }

    .video-list {
        width: 75%;

        // height: 1000px;
        .menu-c {
            height: 40px;
            padding-left: 5px;

            display: flex;
            justify-content: flex-start;

            .menu-c-item {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 1px;
                font-size: 18px;
                font-weight: bold;
                padding: 0 15px;
                border-radius: 20px;
                color: rgb(128, 128, 128);
            }

            .menu-c-item:hover {
                background-color: rgb(58,162,15);
            }

            .menu-c-item-check {
                background-color: rgb(58,162,15);
                color: rgb(250, 250, 250);
            }

        }

        .menu-gc {
            // margin-top: 5px;
            min-height: 30px;
            // background-color: aqua;
            display: flex;
            // justify-content: flex-start;
            // overflow-x: auto;
            // display: -webkit-box;
            flex-wrap: wrap;
            padding: 12px;
            padding-bottom: 5px;
            // overflow: auto;

            .menu-gc-item {
                // background-color: red;
                // width: 50px;
                display: flex;
                align-items: center;
                padding: 5px 10px;
                // border-radius: 15px;
                font-size: 15px;
                font-weight: bold;
                color: rgb(128, 128, 128);
            }
            .menu-gc-item-active {
                color: rgb(58,162,15);
            }
        }

        .list {
            // background-color: bisque;
            // height: 1000px;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;

            .list-item {
                width: 28%;
                height: 280px;
                margin: 2%;
                // background-color: aquamarine;

                .item-image {
                    height: 230px;
                    border-radius: 20px;

                    .image {
                        transition: all 0.2s linear;
                    }
                    .image:hover {
                        transform: scale(1.05);
                    }
                }

                .item-title {
                    margin: 10px 0 0 10px;
                    font-size: 15px;
                    color: rgb(50, 50, 50);
                }

                .item-time {
                    font-size: 14px;
                    color: rgb(128, 128, 128);
                    margin: 5px 0 0 10px;
                }
            }
        }
    }
}</style>
